<template>
  <span>
    <WordCarousel
      :beforeMessage="wordCarousel.beforeMessage"
      :Messages="wordCarousel.Messages"
      :afterMessage="wordCarousel.afterMessage"
      :Time="wordCarousel.Time"
      :textColor="wordCarousel.TextColor"
      :bgColor="wordCarousel.BgColor"
    />
  </span>
</template>

<script>
export default {
  components: { WordCarousel: () => import("./WordCarousel") },
  props: {
    messages: { type: Array },
    textColor: { type: String },
    bgColor: { type: String },
    beforeMessage: { type: String },
    afterMessage: { type: String },
    time: { type: Number },
  },
  data() {
    return {
      wordCarousel: {
        beforeMessage: this.beforeMessage || "{",
        Messages: this.messages || [
          "Bienvenue sur ma WebApp",
          "Je m'appelle Julien",
          "Je vis à Nice",
          "Je suis Développeur Web junior",
          "Je build avec Vue.js",
          "J'utilise Bootstrap 4",
          "Je maitrise PHP Orienté-objet",
          "Je build avec Express.js [PUG,TWIG]",
          "Je travaille en methode Agile",
          "Je réalise avec MySQL [MCD,MLD]",
          "J'utilise le versioning GIT [hub,lab]",
          "Je suis à votre service",
        ],
        afterMessage: this.afterMessage || "}",
        Time: this.time || 3000,
        TextColor: this.textColor || "#ffc107",
        BgColor: this.bgColor || "transparent",
      },
    };
  },
  beforeDestroy() {
    this.wordCarousel = undefined;
  },
};
</script>

<style scoped>
@media screen and (min-width: 1200px) {
  h1 {
    margin-top: 1vw;
    font-size: 2vw;
  }
}
</style>